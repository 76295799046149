// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-area-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/practice-area.js" /* webpackChunkName: "component---src-pages-practice-area-js" */),
  "component---src-pages-services-js": () => import("/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/bh/Documents/Sourcetree/loggenbergattorneys-coza/.cache/data.json")

