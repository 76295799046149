import React from "react"
import firebase from "../workers/firebase"
//const firebase = Firebase;

// Context api seems to be good for low frequency changes
// Seems like its not recomended for reacting to every keystroke
// React Context vs Redux - Who wins

// defaultContext seems like its not really needed but that it helps with the autocomplete
// React Context vs Redux - Who wins
let defaultContext = {}

/*
// Consider letting the user pass in an object with custom context
let compileDefaultContext = (myContext) => {

   defaultContext.myContext = myContext

   return defaultContext
}
*/

const ContextYo = React.createContext(defaultContext)

class ContextYoProvider extends React.Component {

   // Not sure what the difference is between setting the 
   // state and methods here or passing it in as defaultContext
   // Seems like its important to put the state and methods here
 
   state = {
      name: "ContextYoProvider Name",
      inputs: {
         name: "",
         content: "",
         email: "",
         password: "",
      },
      user: {
         email: "",
         bio: ""
      }
   }

   componentDidMount() {

      let self = this;

      if (typeof window !== 'undefined') {
         //console.log("ContextYo firebase.auth.currentUser");
         //console.log(firebase.auth.currentUser);
         //console.log(this.state);
         //console.log("ContextYo this.state");
         //console.log(this.state);
         // Set the auth status checkers
         firebase.auth.onAuthStateChanged(user =>{
            //console.log("ContextYo Auth State Changed")
            if (user) {
               //console.log(user)
               return firebase.db.collection("users").doc(user.uid).get().then( doc =>{
                  const uid = user.uid;
                  const email = doc.data().email;
                  const bio = doc.data().bio;
                  const claims = doc.data().claims;

                  //console.log(bio)
                  self.setState({
                     user: {
                        uid,
                        email,
                        bio,
                        claims
                     }
                  })
               });
            }
            else {
               console.log("No user")
               self.setState({
                  user: "No user"
               })
            }
         })
      }
   }
  
   changeName = (e) => {

      e.preventDefault();

      // Maybe save the data to the localStorage
      // localStorage.setItem("dark", JSON.stringify(dark))

      if (this.state.inputs){
         if (this.state.inputs.contextname){
            this.setState({ 
               name: this.state.inputs.contextname
            })
         }
      }

   }

   /*
   // Consider getting data from localStorage and setting it to localstorage
   componentDidMount() {

      const lsDark = JSON.parse(localStorage.getItem("dark"))
      if (lsDark) {
      this.setState({ dark: lsDark })
      } else if (supportsDarkMode()) {
      this.setState({ dark: true })
      }
         
   }
   */

   /*
      value={{
         dark,
         toggleDark: this.toggleDark,
      }}
   */

   handleInputChange = (e) => {

      e.preventDefault();
      const id = e.target.id;

      let oldInputState = this.state.inputs;
      let newInputState = oldInputState;
      newInputState[id] = e.target.value
      //console.log("ContextYo newInputState")
      //console.log(newInputState)
      this.setState(newInputState);
   };

   handleSignUp = (e) => {

      e.preventDefault();

      //const displayName = this.state.inputs.displayName
      const email = this.state.inputs.email;
      const password = this.state.inputs.password;
      const bio = (this.state.inputs.bio) ? this.state.inputs.bio : "No Bio";

      // Do the firebase signup
      firebase.auth.createUserWithEmailAndPassword(email, password).then(cred => {

         return firebase.db.collection('users').doc(cred.user.uid).set({
            email: email,
            bio: bio
         })

      }).then(()=>{

         // Close the dialog and clear the form
         this.setState({ 
            open: false,
            inputs: {
               email: "",
               password:"",
               bio: ""
            },
            errorState: "No Error"
         });

      }).catch(err => {
         this.setState({
            errorState: err.message
         })
         console.log(err.message);
      })
      
   };

   handleSignIn = (e) => {

      let self = this;
      e.preventDefault();

      //const displayName = this.state.inputs.displayName
      const email = self.state.inputs.email;
      const password = self.state.inputs.password;

      // Do the firebase signup
      firebase.auth.signInWithEmailAndPassword(email, password).then(cred => {
         // Close the dialog and clear the form
         self.setState({ 
            inputs: {},
            errorState: "No Error"
         });
         //console.log("ContextYo Test: cred");
         //console.log(cred);
      }).catch(err => {
         self.setState({
            errorState: err.message
         })
      })
   };

   handleSignOut = (e) => {

      let self = this;
      e.preventDefault();

		// Sign the user out of firebase
		firebase.auth.signOut().then(()=>{
		console.log("User Signed Out");
		})
		self.setState({ 
			errorState: "No Error"
		});
   };

   handleCreate = (e) => {

      console.log("contextYo - handleCreate")
		e.preventDefault();
		//const displayName = this.state.inputs.displayName
		const name = this.state.inputs.name;
		const content = this.state.inputs.content;

		firebase.db.collection('test').add({
			name: name,
			content: content
		}).then(()=>{
			this.setState({ 
				open: false,
				inputs: {
					name: "",
					content: ""
				}
         });
         

         /*
         const uid = this.state.user.uid;

         return firebase.db.collection("users").doc(uid).get().then( doc =>{
            const email = doc.data().email;
            const bio = doc.data().bio;
            const claims = doc.data().claims;
            console.log(bio)
            self.setState({
               user: {
                  email,
                  bio,
                  claims
               }
            })
         });
         */

		}).catch(err => {
			console.log(err.message);
		})

   };
   
   handleDelete = (e) => {

      //console.log("contextYo - handleDelete")
      //console.log(e.target.id);
      e.preventDefault();
      let documentID = ""
      if (e.target){
         if (e.target.id){
            documentID = e.target.id
         }
      }

		firebase.db.collection(`test`).doc(documentID).delete().then(()=>{
			this.setState({ 
				open: false,
				inputs: {
					name: "",
					content: ""
				}
			});
		}).catch(err => {
			console.log(err.message);
		})

	};

   
   makeAdmin = () => {
      const emailForAdmin = this.state.emailtobeadmin;
      //console.log("emailForAdmin");
      //console.log(emailForAdmin);

      const addAdminRoleFunction = firebase.functions.httpsCallable("addAdminRole");

      addAdminRoleFunction({email: emailForAdmin}).then( result => {
         console.log(result);
      })
   };

   render() {
      const { children } = this.props
      //const { dark } = this.state

      
      return (

         <ContextYo.Provider value={{
            name: this.state.name,
            inputs: this.state.inputs,
            user: this.state.user,
            changeName: this.changeName,
            handleInputChange: this.handleInputChange,
            handleSignIn: this.handleSignIn,
            handleSignOut: this.handleSignOut,
            handleSignUp: this.handleSignUp,
            handleCreate: this.handleCreate,
            handleDelete: this.handleDelete,
            makeAdmin: this.makeAdmin
         }}>
            
            {children}
             
         </ContextYo.Provider>
      )
   }
}

let ContextYoConsumer = ContextYo.Consumer

export default ContextYo

export { 
   ContextYo,
   ContextYoProvider,
   ContextYoConsumer
 }