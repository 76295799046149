import React from "react";
import { Component, Fragment } from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types";
import SEO from './components/seo';
import Normalize from './components/normalize';
import styled from "styled-components";
import { observer, inject } from 'mobx-react';
import { observable, action, decorate } from "mobx"
import ContextYo, { ContextYoProvider, ContextYoConsumer } from "./components/contextyo";
import firebase from "./workers/firebase";
import ImageYo from "./components/imageyo";

export { 
  React,
  Component,
  Fragment,
  PropTypes,
  SEO,
  Normalize,
  Link,
  styled,
  observer, 
  inject,
  observable, 
  action, 
  decorate,
  ContextYo,
  ContextYoProvider, 
  ContextYoConsumer,
  firebase,
  ImageYo
};

/*

#todo

✓ MobX
✓ React Context
✓ Firebase Firestore
✓ Firebase Auth
✓ Firebase Functions
✔︎ ImageYo

Maybe make the function add the claims to the firesore 
user database to make it easier to Show auth claims on page

Material UI
*/

/*

Consider setting up firebase like this:
https://github.com/gatsbyjs/gatsby/issues/6386

*/